import {
	SimpleTable,
	theme as Theme,
	Trials,
	Typography,
} from '@lh/eng-web-mosaic-common';
import { CellContext } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { BreakdownDCRExpanded } from './BreakdownDCRExpanded';
import { useResolveBatteryResult } from '../../../../../../hooks/batteryResults';
import { LoadingDots } from '../../../../../shared/LoadingDots';
import { useBreakdownMeasuresStore } from '../../../../../../store';
import { BreakdownContainer, Header, StyledButton } from './Breakdown.style';
import { BreakdownProps, ScoreProps } from './Breakdown.types';
import { groupIntoTwos } from './Breakdown.utils';

export function BreakdownDCR({
	expanded,
	batteryResultId,
	getData,
}: Readonly<BreakdownProps>) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const params = useParams();

	const visuoconstructionalMeasures = useBreakdownMeasuresStore(
		(state) => state.visuoconstructionalMeasures
	);
	const ready = useBreakdownMeasuresStore((state) => state.ready);
	const { data: deepBatteryResult, loading } = useResolveBatteryResult({
		batteryResultId: batteryResultId ?? '',
	});

	const { visuoconstructional } = useMemo(
		() => getData(deepBatteryResult),
		[deepBatteryResult, getData]
	);

	const scores: ScoreProps[] = [{ visuoconstructional }];

	if (loading || !ready) {
		return <LoadingDots />;
	}

	return (
		<BreakdownContainer>
			<Header>
				<Typography.P2 color={Theme.color.bodyTextSecondary}>
					{t('web.report.cohort.breakdown.dcr').toUpperCase()}
				</Typography.P2>
				<StyledButton
					onClick={() =>
						navigate(
							`/results/${params.participantId}/${batteryResultId}`
						)
					}
					role='link'>
					{t('web.report.cohort.breakdown.viewReport')}
				</StyledButton>
			</Header>
			<SimpleTable<ScoreProps>
				data={scores}
				withColumnBorders={expanded}
				columns={[
					{
						accessorKey: 'visuoconstructional',
						header: () => {
							return (
								<TrialsWrapper>
									<Trials
										variant={
											visuoconstructionalMeasures !== 0
												? 'Red'
												: 'Green'
										}
										title={t(
											'web.report.cohort.breakdown.visuoconstructional'
										)}
										score={`${visuoconstructionalMeasures}/${visuoconstructional.length}`}
										responseText={t(
											'web.report.cohort.breakdown.impairedMeasures'
										)}
									/>
								</TrialsWrapper>
							);
						},
						cell: (props: CellContext<ScoreProps, unknown>) => {
							const scores =
								props.getValue<
									ScoreProps['visuoconstructional']
								>();

							if (!scores || !expanded) return null;

							const _scores = groupIntoTwos(scores);

							return <BreakdownDCRExpanded scores={_scores} />;
						},
					},
				]}
				striped={false}
				borderColor={Theme.colors.gray_60}
				horizontalSpacing={Theme.spacing.sm}
				verticalSpacing={0}
			/>
		</BreakdownContainer>
	);
}

const TrialsWrapper = styled.div`
	padding: 8px 0;
`;
