import { DeepBatteryResult } from '@lh/eng-platform-battery-service-rest-client';
import { theme as Theme, Typography } from '@lh/eng-web-mosaic-common';

import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useAuthServiceContext } from 'features/auth-service';
import { AnalyticsAction } from 'generated/graphql';
import { sendEventData } from '../../../../../analytics/amplitude';
import { StyledLinkButton } from '../../../../../components/shared/StyledLinkButton';
import {
	Icon,
	LoadingButton,
} from '../../../../../components/shared/designSystem';
import { UserContext } from '../../../../../context/UserContext';
import { icons } from '../../../../../enums/icons';
import {
	AnalyticsLanguage,
	MAX_ATTEMPTS,
	PDFDownloadLinkResponse,
	POLLING_INTERVAL,
	PdfGenerateInput,
	onGetReportData,
	getReportName,
} from '../../../../../pdfReportsUtils';
import { TableData } from '../../../../../schemas/table/participantDetailsSchema';
import { useBreakdownMeasuresStore } from '../../../../../store';
import { LoadingDots } from '../../../../shared/LoadingDots';
import { ScoresClassification } from '../ScoresClassification';

import { BreakdownDCR, BreakdownRDAC } from './Breakdown';
import { MeasuresProps } from './Breakdown/Breakdown.types';
import { IndicativeKeys, getBreakdownData } from './Breakdown/Breakdown.utils';
import {
	ButtonContainer,
	Card,
	Ellipsis,
	Header,
	HeaderClassification,
	Indicative,
	ReportsContainer,
	StyledButton,
	StyledDivider,
} from './ParticipantClassification.style';
import { getBreakdownProps } from './ParticipantClassification.utils';

export interface ParticipantClassificationProps {
	results: TableData[];
	latestDacBatteryResultId: string;
	participantLanguage?: string;
}

export function ParticipantClassification({
	results,
	latestDacBatteryResultId,
	participantLanguage,
}: Readonly<ParticipantClassificationProps>) {
	const { t } = useTranslation();
	const auth = useAuthServiceContext();
	const { currentUser } = useContext(UserContext);

	const generateReportInputRef = useRef<PdfGenerateInput | null>(null);

	const indicative = useBreakdownMeasuresStore((state) => state.indicative);
	const setMeasures = useBreakdownMeasuresStore((state) => state.setMeasures);
	const visuoconstructionalMeasures = useBreakdownMeasuresStore(
		(state) => state.visuoconstructionalMeasures
	);
	const episodicMemoryMeasures = useBreakdownMeasuresStore(
		(state) => state.episodicMemoryMeasures
	);
	const executiveControlMeasures = useBreakdownMeasuresStore(
		(state) => state.executiveControlMeasures
	);

	const setReady = useBreakdownMeasuresStore((state) => state.setReady);
	const ready = useBreakdownMeasuresStore((state) => state.ready);
	const [isScoresClassificationOpened, setIsScoresClassificationOpened] =
		useState(false);
	const [expanded, setExpanded] = useState(false);
	const [loadingData, setLoadingData] = useState(false);

	const handleBreakdownCollapse = useCallback(() => {
		setExpanded((s) => !s);
	}, [setExpanded]);

	const { dcrResultId, dcrParticipantId, dacResultId, dacParticipantId } =
		getBreakdownProps({ results });
	useEffect(() => {
		if (!dcrResultId)
			setMeasures({
				visuoconstructionalMeasures: -1,
			});
		if (!dacResultId)
			setMeasures({
				episodicMemoryMeasures: -1,
				executiveControlMeasures: -1,
			});
	}, [dcrResultId, dacResultId, setMeasures]);

	const getData = useCallback(
		(br: DeepBatteryResult | null) => {
			const measures: MeasuresProps = {};

			const { episodicMemory, executiveControl, visuoconstructional } =
				getBreakdownData(br);
			if (br && br.id === dacResultId) {
				measures.episodicMemoryMeasures = episodicMemory.filter(
					(item) => item.isImpaired
				).length;
				measures.executiveControlMeasures = executiveControl.filter(
					(item) => item.isImpaired
				).length;
			} else if (br && br.id === dcrResultId) {
				measures.visuoconstructionalMeasures =
					visuoconstructional.filter(
						(item) => item.isImpaired
					).length;
			}
			setMeasures(measures);
			return {
				episodicMemory,
				executiveControl,
				visuoconstructional,
			};
		},
		[setMeasures, dacResultId, dcrResultId]
	);

	async function handlePollingSuccess(response: PDFDownloadLinkResponse) {
		if (!response?.downloadLink) return;

		if (generateReportInputRef.current) {
			sendEventData({
				eventType: AnalyticsAction.DownloadedReport,
				eventProperties: {
					downloadType: 'print PDF',
					language:
						AnalyticsLanguage[
							generateReportInputRef.current.language
						],
					reportName: getReportName(
						generateReportInputRef.current.reportType
					),
					latestDacBatteryResultId,
					source: 'Participant Classification',
				},
			});
		}

		const regex = /response-content-disposition=inline/;
		const result = regex.exec(response.downloadLink);
		if (result) {
			//If the response content disposition is inline (meant to be previewed before downloading)
			const openLink = document.createElement('a');
			openLink.setAttribute('href', response.downloadLink);
			openLink.setAttribute('target', '_blank');
			openLink.click();
		} else {
			//If the file is meant to be downloaded directly.
			window.location.assign(response.downloadLink);
		}
	}

	useEffect(() => {
		if (!dcrResultId && !dacResultId) return;
		if (!dcrResultId) {
			if (
				episodicMemoryMeasures !== -1 &&
				executiveControlMeasures !== -1
			)
				setReady();
		} else if (!dacResultId) {
			if (visuoconstructionalMeasures !== -1) setReady();
		} else if (
			episodicMemoryMeasures !== -1 &&
			executiveControlMeasures !== -1 &&
			visuoconstructionalMeasures !== -1
		) {
			setReady();
		}
	}, [
		dcrResultId,
		dacResultId,
		visuoconstructionalMeasures,
		episodicMemoryMeasures,
		executiveControlMeasures,
		setReady,
	]);
	const showEllipsis =
		indicative?.key !== IndicativeKeys.AssignDCR &&
		indicative?.key !== IndicativeKeys.NotAvailable;
	return (
		<Card>
			<Curtain ready={ready}>
				<ScoresClassification
					isOpen={isScoresClassificationOpened}
					onClose={() => setIsScoresClassificationOpened(false)}
				/>
				<Header data-testid='classification-header'>
					<Typography.H4>
						{t('web.report.cohort.classification.title')}
					</Typography.H4>
					<HeaderClassification>
						<StyledLinkButton
							onClick={() =>
								setIsScoresClassificationOpened(true)
							}
							role='link'>
							{t(
								'web.report.cohort.classification.viewAllScoreClassification'
							)}
						</StyledLinkButton>
						{dacResultId && dcrResultId && (
							<LoadingButton
								text={t('web.report.cohort.pdfDownloadButton')}
								interval={POLLING_INTERVAL}
								maxAttempts={MAX_ATTEMPTS}
								onGetData={() =>
									onGetReportData(
										auth,
										dcrResultId,
										currentUser,
										participantLanguage,
										(input) => {
											generateReportInputRef.current =
												input;
										}
									)
								}
								onPollSuccess={handlePollingSuccess}
							/>
						)}
					</HeaderClassification>
				</Header>
				<StyledDivider />
				{!loadingData ? (
					<Indicative
						$backgroundColor={indicative?.colors.container}
						data-testid='classification-indicative'>
						{showEllipsis && (
							<Ellipsis
								$backgroundColor={indicative?.colors.ellipsis}
							/>
						)}
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}>
							<Typography.H3>
								{indicative?.indicativeText}
							</Typography.H3>
							{indicative?.key ===
								IndicativeKeys.NotAvailable && (
								<Typography.P2>
									{t(
										'web.report.cohort.classification.notAvailable.subtitle'
									)}
								</Typography.P2>
							)}
						</div>
					</Indicative>
				) : (
					<LoadingDots />
				)}
				<ReportsContainer data-testid='classification-reports-container'>
					{dacResultId && (
						<BreakdownRDAC
							getData={getData}
							expanded={expanded}
							batteryResultId={dacResultId}
							participantId={dacParticipantId}
							onLoadingData={setLoadingData}
						/>
					)}
					{dcrResultId && (
						<BreakdownDCR
							getData={getData}
							expanded={expanded}
							batteryResultId={dcrResultId}
							participantId={dcrParticipantId}
							onLoadingData={setLoadingData}
						/>
					)}
				</ReportsContainer>
				<ButtonContainer>
					<StyledButton onClick={handleBreakdownCollapse} role='link'>
						{!expanded
							? t('web.report.cohort.breakdown.fullScore')
							: t('web.report.cohort.breakdown.collapseScore')}
					</StyledButton>
					<Icon
						onClick={handleBreakdownCollapse}
						color={Theme.color.link}
						icon={!expanded ? icons.ArrowDown : icons.ArrowUp}
					/>
				</ButtonContainer>
			</Curtain>
		</Card>
	);
}

const Curtain = styled.div<{ ready: boolean }>(
	({ ready, theme: { color } }) => `
  opacity: ${ready ? 100 : 0}%;
  background-color: ${color.white};
  min-height: 280px;
`
);
