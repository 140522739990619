import { theme as Theme, Typography } from '@lh/eng-web-mosaic-common';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon } from '../../../../../shared/designSystem';

import { getScoreInterpretation, percentileSuffix } from './Breakdown.utils';
import { CurveContainer, BreakdownInfoContainer } from './Breakdown.style';
import { BreakdownInfoProps } from './Breakdown.types';

export function BreakdownInfo({
	isImpaired = false,
	percentile,
	score,
	title,
}: Readonly<BreakdownInfoProps>) {
	const { t } = useTranslation();

	const color = isImpaired ? Theme.colors.orange_darker : Theme.colors.black;
	const _percentile =
		percentile && percentile !== 0 ? parseInt(percentile.toString()) : 0;
	const suffix = percentile
		? percentileSuffix(parseInt(percentile.toString()))
		: '';

	const { icon, score: _score } = getScoreInterpretation(_percentile, score);

	return (
		<BreakdownInfoContainer>
			<Typography.C color={Theme.color.bodyTextSecondary} weight='500'>
				{title}
			</Typography.C>
			<CurveContainer>
				<StyledScore color={color} weight='700'>
					{_score}
				</StyledScore>
				<StyledPercentile color={Theme.color.bodyTextSecondary}>
					{t('web.report.cohort.breakdown.expanded.percentile', {
						value: `${_percentile}${suffix}`,
					})}
				</StyledPercentile>
				<Icon icon={icon} />
			</CurveContainer>
		</BreakdownInfoContainer>
	);
}

const StyledPercentile = styled(Typography.C)`
	margin-right: 8px;
`;
const StyledScore = styled(Typography.P1)`
	margin-right: 2px;
`;
