import {
	SimpleTable,
	theme as Theme,
	Trials,
	Typography,
} from '@lh/eng-web-mosaic-common';

import { CellContext } from '@tanstack/react-table';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { BreakdownRDACExpanded } from './BreakdownRDACExpanded';
import { useResolveBatteryResult } from '../../../../../../hooks/batteryResults';
import { LoadingDots } from '../../../../../shared/LoadingDots';
import { useBreakdownMeasuresStore } from '../../../../../../store';

import { BreakdownContainer, Header, StyledButton } from './Breakdown.style';
import { BreakdownProps, ScoreProps } from './Breakdown.types';

export function BreakdownRDAC({
	expanded,
	batteryResultId,
	getData,
}: Readonly<BreakdownProps>) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const params = useParams();

	const episodicMemoryMeasures = useBreakdownMeasuresStore(
		(state) => state.episodicMemoryMeasures
	);
	const executiveControlMeasures = useBreakdownMeasuresStore(
		(state) => state.executiveControlMeasures
	);

	const ready = useBreakdownMeasuresStore((state) => state.ready);

	const { data: deepBatteryResult, loading } = useResolveBatteryResult({
		batteryResultId: batteryResultId ?? '',
	});

	const { episodicMemory, executiveControl } = useMemo(
		() => getData(deepBatteryResult),
		[deepBatteryResult, getData]
	);

	const scores: ScoreProps[] = [{ episodicMemory, executiveControl }];
	if (loading || !ready) {
		return <LoadingDots />;
	}

	return (
		<BreakdownContainer>
			<Header>
				<Typography.P2 color={Theme.color.bodyTextSecondary}>
					{t('web.report.cohort.breakdown.rdac').toUpperCase()}
				</Typography.P2>
				<StyledButton
					onClick={() =>
						navigate(
							`/results/${params.participantId}/${batteryResultId}`
						)
					}
					role='link'>
					{t('web.report.cohort.breakdown.viewReport')}
				</StyledButton>
			</Header>
			<SimpleTable<ScoreProps>
				data={scores}
				withColumnBorders
				withRowBorders={expanded}
				columns={[
					{
						accessorKey: 'episodicMemory',
						header: () => (
							<TrialsWrapper>
								<Trials
									variant={
										episodicMemoryMeasures !== 0
											? 'Red'
											: 'Green'
									}
									title={t(
										'web.report.cohort.breakdown.episodicMemory'
									)}
									score={`${episodicMemoryMeasures}/${episodicMemory.length}`}
									responseText={t(
										'web.report.cohort.breakdown.impairedMeasures'
									)}
								/>
							</TrialsWrapper>
						),
						cell: (props: CellContext<ScoreProps, unknown>) => {
							const _episodicMemory =
								props.getValue<ScoreProps['episodicMemory']>();

							if (!_episodicMemory || !expanded) return null;

							return (
								<BreakdownRDACExpanded
									scores={_episodicMemory}
								/>
							);
						},
					},
					{
						accessorKey: 'executiveControl',
						header: () => (
							<TrialsWrapper>
								<Trials
									variant={
										executiveControlMeasures !== 0
											? 'Red'
											: 'Green'
									}
									title={t(
										'web.report.cohort.breakdown.executiveControl'
									)}
									score={`${executiveControlMeasures}/${executiveControl.length}`}
									responseText={t(
										'web.report.cohort.breakdown.impairedMeasures'
									)}
								/>
							</TrialsWrapper>
						),
						cell: (props: CellContext<ScoreProps, unknown>) => {
							const _executiveControl =
								props.getValue<
									ScoreProps['executiveControl']
								>();

							if (!_executiveControl || !expanded) return null;

							return (
								<BreakdownRDACExpanded
									scores={_executiveControl}
								/>
							);
						},
					},
				]}
				striped={false}
				borderColor={Theme.colors.gray_60}
				horizontalSpacing={0}
				verticalSpacing={0}
			/>
		</BreakdownContainer>
	);
}

const TrialsWrapper = styled.div`
	padding: 8px 12px;
`;
