import { Space } from '@mantine/core';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@lh/eng-web-mosaic-common';

import { theme } from '../../../../../providers/styleProvider';
import { ParticipantDataWithResults } from '../../useGetParticipantDetails';
import { IndicationWidgetRenderer } from './components/IndicationWidgetRenderer';
import { getMostRecentResult } from './helpers/indicationWidgetHelpers';
import { useGetResultFlagsConfig } from './helpers/useGetResultFlagsConfig';

type Props = {
	participantData: ParticipantDataWithResults;
};

export const ResultFlags = ({ participantData }: Props) => {
	const { t } = useTranslation();

	const indicationWidgetConfig = useGetResultFlagsConfig();

	const getMostRecentBatteryResult = useCallback(
		(batteryDisplayKey: string) => {
			return getMostRecentResult(
				participantData?.deepBatteryResults,
				batteryDisplayKey
			);
		},
		[participantData?.deepBatteryResults]
	);

	// Returns true if our widget config is able to find any of it's indications to be true for a matching (by battery display key) battery result.
	// Note: We only need this here because we want to be able to hide the "Result Flags" section header if no indicative widgets are to be displayed.
	const hasFlaggedResults = useMemo(() => {
		return indicationWidgetConfig.some(
			({ indications, batteryDisplayKey }) => {
				return indications?.some(({ isIndicative }) =>
					isIndicative(getMostRecentBatteryResult(batteryDisplayKey))
				);
			}
		);
	}, [getMostRecentBatteryResult, indicationWidgetConfig]);

	if (!hasFlaggedResults) return null;

	return (
		<div>
			<Typography.H3 weight='500' color={theme?.color?.bodyTextSecondary}>
				{t('web.resultFlags.heading')}
			</Typography.H3>
			<Space h='sm' />
			<IndicationWidgetRenderer
				batteryResults={participantData?.deepBatteryResults}
			/>
			<Space h='sm' />
		</div>
	);
};
